import React, { useEffect, useRef, useState, type ReactElement } from 'react'
import styled from 'styled-components'
import { type DropzoneRootProps, useDropzone } from 'react-dropzone'
import { isDesktop } from '../../utils/detect-screen'
import { CIconButton } from '../mui/IconButton'
import getURLMetaData from '../../utils/getURLMetaData'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'

const getColor = (props: any): string => {
  if (props.isDragAccept === true) {
    return props.theme.blue100
  }
  if (props.isDragReject === true) {
    return props.theme.red100
  }
  if (props.isFocused === true) {
    return props.theme.black80
  }

  return props.theme.black12
}

const Container = styled.div<
  DropzoneRootProps & { $marginbottom?: string; $disabled?: boolean }
>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${props => getColor(props)};
  background-color: ${props => props.theme.gray40};
  color: ${props => props.theme.black50};
  outline: none;
  transition: all 50ms ease-in-out;
  font-size: 14px;
  height: 220px;
  margin-bottom: ${props => props.$marginbottom ?? '0'};
  border: 1px solid ${props => props.theme.white30};

  &:hover {
    border: 1px solid ${props => props.theme.gray40};
    cursor: ${({ $disabled }) =>
      $disabled === true ? 'not-allowed' : 'pointer'};
  }

  > * {
    &:hover {
      cursor: ${({ $disabled }) =>
        $disabled === true ? 'not-allowed' : 'pointer'};
    }
  }

  &.has-error {
    border-color: ${props => props.theme.red100};
  }

  > .description {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    text-align: right;
    > p {
      text-align: center;
    }
  }

  > .files {
    padding: 10px 10px 0 10px;
    display: flex;

    > .files {
      > .file {
        position: relative;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.white30};
        margin-left: 10px;
        margin-bottom: 10px;
        position: relative;

        > .removeIcon {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 2; /* Ensure it stays above other elements */
          cursor: pointer;
          background-color: transparent !important;
        }
      }
    }

    > .file {
      padding: 10px;
      border-radius: 8px;
      border: 1px solid ${props => props.theme.white30};
      margin-left: 10px;
      margin-bottom: 10px;
      position: relative;

      > .removeIcon {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 2; /* Ensure it stays above other elements */
        cursor: pointer;
      }

      > img {
        width: 200px;
        height: 120px;
      }

      &:last-child {
        margin-left: 0;
      }

      > span {
        font-size: 12px;
        color: ${props => props.theme.white100};
        direction: ltr;
        display: flex;
        width: 260px;
        height: 40px;
      }

      > .icon {
        display: block;
        margin: 0 auto 10px auto;
        text-align: center;
        color: ${props => props.theme.black50};
      }
    }
  }
`

const ErrorText = styled.p`
  text-align: right;
  font-family: Inter;
  font-weight: 500;
  font-size: 10px;
  color: ${({ theme }) => theme.red100};
  margin-right: 8px;
  margin-top: 8px;
`

export interface DropZoneProps {
  marginBottom?: string
  disabled?: boolean
  helperText?: string
  selectedFiles: File[] | undefined
  onSelect?: (files: File[]) => void
  onSelectAvatars?: (files: File[]) => void
  onSelectCovers?: (files: File[]) => void
  onImageDelete?: (isDeleted: boolean) => void
  acceptedFiles?: string[]
  maxFiles?: number
  label: string
  image?: string
  type?: string
  maxSize?: number
  isModalClosed?: boolean
}

export function DropZone(props: DropZoneProps): ReactElement {
  const {
    marginBottom = 0,
    disabled = false,
    helperText,
    acceptedFiles,
    selectedFiles,
    onImageDelete,
    onSelect,
    onSelectAvatars = () => {},
    onSelectCovers = () => {},
    maxFiles = 1,
    label,
    image,
    type,
    maxSize = 100,
  } = props

  const user = useAppSelector(state => state.user)

  const onDrop = React.useCallback((acceptedFiles: any) => {
    if (!disabled) {
      handleSelect(acceptedFiles)
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
  })

  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  const [errorText, setErrorText] = useState<string>('')
  const handleSelect = (files: File[]): void => {
    setErrorText('')
    let hasError = false

    if (files.length > maxFiles) {
      setErrorText(`Cannot select more than ${maxFiles} files`)
      hasError = true
    } else {
      if (acceptedFiles != null && files.length > 0) {
        for (const file of files) {
          // Only check file type if acceptedFiles is not '*/*'
          if (
            acceptedFiles[0] !== '*/*' &&
            !acceptedFiles.includes(file.type)
          ) {
            setErrorText(
              'The file format you uploaded is not supported. Supported formats are: ' +
                acceptedFiles
                  .join(', ')
                  .replaceAll('image/', '')
                  .replaceAll('video/', '')
            )
            hasError = true
          } else {
            if (file.size / 1024 >= maxSize * 1024) {
              setErrorText(
                `The file you uploaded exceeds the ${maxSize} MB limit. Please upload a file that is smaller.`
              )
              hasError = true
            }
          }
        }
      }
    }

    if (!hasError) {
      if (onSelect) onSelect(files) // General files
      if (type === 'avatar' && onSelectAvatars) onSelectAvatars?.(files) // Avatars
      if (type === 'cover' && onSelectCovers) onSelectCovers?.(files) // Covers
    }
  }

  const [contentType, setContentType] = useState<string>('')

  useEffect(() => {
    const fetchMetadata = async () => {
      if (!image) return
      const result = await getURLMetaData(image)
      setContentType(result?.contentType)
    }

    fetchMetadata() // Call the async function
  }, [image])

  const [isProfilePage, setIsProfilePage] = useState<boolean>(false)

  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes('profile')) {
      setIsProfilePage(true)
    } else {
      setIsProfilePage(false)
    }
  })

  const handleRemoveAll = (): void => {
    if (onImageDelete) {
      onImageDelete(true)
    }
    if (!isProfilePage) {
      onSelect([])
    }
  }

  useEffect(() => {
    if (isProfilePage) {
      if (!user?.avatar) {
        onSelectAvatars([])
      }
      if (!user?.cover) {
        onSelectCovers([])
      }
    }
  }, [user, isProfilePage])


  const renderFilePreview = (file: File) => {
    if (file.type.includes('image')) {
      onImageDelete(false)
      return (
        <img
          src={URL.createObjectURL(file)}
          alt=""
          style={{ width: '200px', height: '120px' }}
        />
      )
    } else if (file.type.includes('video')) {
      onImageDelete(false)
      return (
        <video style={{ width: '200px', height: '120px' }}>
          <source src={URL.createObjectURL(file)} />
        </video>
      )
    }
    return null
  }

  const renderRemotePreview = () => {
    if (contentType === 'image') {
      return (
        <div className="files">
          <div className="file">
            <img
              src={image}
              alt=""
              style={{
                width:
                  type === 'avatar'
                    ? '100px'
                    : type === 'cover'
                    ? isDesktop()
                      ? '500px'
                      : '200px'
                    : '200px',
                height: type === 'avatar' ? '100px' : '120px',
              }}
            />
            <div
              className="removeIcon"
              onClick={e => {
                e.stopPropagation()
                handleRemoveAll()
              }}
            >
              <CIconButton
                icon="close"
                borderRadius="50%"
                backgroundColor="white40"
                backgroundColorHover="white40"
                borderColor="white100"
                customColor="black"
                customColorHover="black"
                padding="1px"
              />
            </div>
          </div>
        </div>
      )
    } else if (contentType === 'video') {
      return (
        <div className="files">
          <div className="file">
            <video
              style={{ width: '200px', height: '120px', borderRadius: '8px' }}
            >
              <source src={image} />
            </video>
            <div
              className="removeIcon"
              onClick={e => {
                e.stopPropagation()
                handleRemoveAll()
              }}
            >
              <CIconButton
                icon="close"
                borderRadius="50%"
                backgroundColor="white40"
                backgroundColorHover="white40"
                borderColor="white100"
                customColor="black"
                customColorHover="black"
                padding="1px"
              />
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <>
      <Container
        $marginbottom={marginBottom}
        $disabled={disabled}
        className={`${helperText || errorText ? 'has-error' : ''}`}
        {...getRootProps({ isFocused, isDragAccept, isDragReject })}
      >
        {!disabled && <input {...getInputProps()} />}

        <div className="files">
          {selectedFiles
            ? selectedFiles.map((file, i) => (
                <div
                  className="file"
                  key={i}
                  onClick={e => {
                    e.stopPropagation()
                    const files = selectedFiles.filter(
                      x => x.name !== file.name
                    )
                    onSelect(files)
                    onSelectAvatars(files)
                    onSelectCovers(files)
                  }}
                >
                  {renderFilePreview(file)}
                  <div
                    className="removeIcon"
                    onClick={e => {
                      e.stopPropagation()
                      handleRemoveAll()
                    }}
                  >
                    <CIconButton
                      icon="close"
                      borderRadius="50%"
                      backgroundColor="white40"
                      backgroundColorHover="white40"
                      borderColor="white100"
                      customColor="black"
                      customColorHover="black"
                      padding="1px"
                    />
                  </div>
                </div>
              ))
            : renderRemotePreview()}
        </div>

        <div className="description">
          {helperText ? (
            <ErrorText>{helperText}</ErrorText>
          ) : errorText ? (
            <ErrorText>{errorText}</ErrorText>
          ) : isDragActive ? (
            <p>Drop Your Files Here</p>
          ) : (
            <p>
              Drop or Click ({label}){' '}
              {acceptedFiles
                ?.join(', ')
                .replaceAll('image/', '')
                .replaceAll('video/', '')}
            </p>
          )}
        </div>
      </Container>
    </>
  )
}
