import { useAppSelector } from '../../redux/hooks'
import { ReactElement, useEffect, useState } from 'react'
import { Page } from '../../components/structure'
import { PostBox } from '../../components/post/box'
import { NothingFound } from '../../components/custom'
import { CButton, CTab, CTabs } from '../../components/mui'
import { ProfileBox } from '../../components/profile'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { UsersBox } from '../../components/user'
import { breakpoints } from '../../config/global-styles'
import { PostModel } from '../../models/post.model'
import { findAllPosts } from '../../apis/post.apis'
import { ProfileModel } from '../../models/profile.model'
import { SearchAllUsers } from '../../apis/user.api'
import { isDesktop, isMobile, isTablet } from '../../utils/detect-screen'
import InfiniteScroll from 'react-infinite-scroll-component'


const TabBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  position: sticky;
  top: 0;
  z-index: 20;

  > span {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.black80};
  }
`

const AdvertiseBox = styled.div`
  padding: 16px;
  background: ${props => props.theme.navy90};
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;

  > img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  > h5 {
    font-size: 22px;
    font-weight: 500;
    color: ${props => props.theme.white100};
    text-align: center;
    display: block;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  > p {
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.white100};
    text-align: justify;
    display: block;
    margin-bottom: 30px;
    line-height: 28px;
    margin-top: 30px;
  }

  > .mobile {
    display: none;
    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      display: flex;
      overflow: hidden;
      width: 100%;
      gap: 16px;
      height: 160px;
    }

    > img {
      display: block;
      width: 142px;
      flex-basis: 40%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    > .column {
      flex-basis: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /* margin-left: 10px; */
      /* gap: 0.5rem; */

      > p {
        font-size: 14px;
        font-weight: 400;
        color: ${props => props.theme.white100};
        /* text-align: justify; */
        display: block;
        /* margin-bottom: 30px; */
        line-height: 17px;
      }

      > a {
        > .MuiButtonBase-root {
          width: fit-content !important;
        }
      }
    }
  }
`

export function HomePage(): ReactElement {
  const user = useAppSelector(state => state.user)

  const [loading, setLoading] = useState<boolean>(true)
  const [usersLoading, setUsersLoading] = useState<boolean>(true)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false)
  const [cursor, setCursor] = useState<string>('')
  const [tab, setTab] = useState<'allPosts' | 'followingPosts' | 'minePosts'>(
    'allPosts'
  )
  const [users, setUsers] = useState<Array<ProfileModel>>([])
  const [posts, setPosts] = useState<Array<PostModel>>([])

  /* 
Scrolls the window to the top-left corner when the component mounts:
- Uses `window.scrollTo` with smooth scrolling to reset the scroll position to `(0, 0)`.
- This effect runs only once on component mount due to the empty dependency array (`[]`).
*/
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  /* 
Fetches all posts with optional filtering and pagination:
- Sets `loading` and `paginationLoading` to true to indicate data retrieval is in progress.
- Calls `findAllPosts` to fetch posts, passing parameters for pagination (`_cursor`) and optional profile filtering (`profiles`).
- On success:
  - Appends the new posts to the existing `posts` state.
  - Updates the `cursor` state for the next paginated request.
- Resets the `loading` and `paginationLoading` states after the request completes or if an error occurs.
*/
  const fetchAllPosts = (_cursor: string, profiles?: any) => {
    // setLoading(true)
    setPaginationLoading(true)

    findAllPosts({
      numberPerPage: 5,
      cursor: _cursor,
      search: { profileIDs: profiles ?? '', q: '' },
    })
      .then(result => {
        if (result) {
          if (result.data.posts.length > 0) {
            setPosts(prevPosts => {
              const existingIds = new Set(prevPosts.map(post => post.id))
              const newPosts = result.data.posts.filter(
                post => !existingIds.has(post.id)
              )
              return [...prevPosts, ...newPosts]
            })
            setCursor(result.data.cursor)
            if (result.data.posts.length < 5) {
              setHasMore(false)
            } else {
              setHasMore(true)
            }
          } else {
            setHasMore(false)
          }
        } else {
          setHasMore(false)
        }
      })
      .catch(() => {
        setLoading(false)
        setPaginationLoading(false)
        setHasMore(false)
      })
      .finally(() => {
        setLoading(false)
        setPaginationLoading(false)
      })
  }

  /* 
Handles loading more posts based on the active tab:
- Checks the current `tab` state to determine which type of posts to fetch:
  - `allPosts`: Fetches all available posts using the current `cursor`.
  - `followingPosts`: Fetches posts from profiles the user is following.
  - `minePosts`: Fetches posts created by the current user.
- Calls `fetchAllPosts` with appropriate parameters for the selected tab.
*/
  const loadMoreHandler = () => {
    if (tab == 'allPosts') {
      fetchAllPosts(cursor)
    } else if (tab == 'followingPosts') {
      fetchAllPosts(
        cursor,
        user?.followings?.map(item => item.targetProfile.id)
      )
    } else if (tab == 'minePosts') {
      fetchAllPosts(cursor, [user.id])
    }
  }

  /* 
Handles changing the post view type (tab):
- Updates the active `tab` state to the selected view type (`newValue`).
- Resets the posts list (`setPosts([])`) and flags (`setHasMore(false)`).
- Resets the cursor state to an empty string (`setCursor('')`) for fetching data from the start.
- Fetches posts based on the selected tab:
  - `allPosts`: Fetches all posts.
  - `followingPosts`: Fetches posts from profiles the user follows.
  - `minePosts`: Fetches posts created by the current user.
- Logs the user's followings to the console when switching to `followingPosts`.
*/
  const handleChangeViewType = (event: any, newValue: any): void => {
    setTab(newValue)
    setPosts([])
    setHasMore(false)
    if (newValue == 'allPosts') {
      setCursor('')
      fetchAllPosts('')
    } else if (newValue == 'followingPosts') {
      setCursor('')
      fetchAllPosts(
        '',
        user?.followings?.map(item => item.targetProfile.id)
      )
    } else if (newValue == 'minePosts') {
      fetchAllPosts('', [user.id])
    }
  }

  /* 
Fetches all users with pagination support:
- Sets `usersLoading` to true to indicate the loading state.
- Calls `SearchAllUsers` with parameters for an empty search query, starting cursor, and 30 results per page.
- On success:
  - Updates the `users` state with the retrieved users.
  - Sets `usersLoading` to false to indicate the loading is complete.
- On error:
  - Sets `usersLoading` to false to handle the failure state.
*/
  const getAllUsers = () => {
    setUsersLoading(true)

    SearchAllUsers({
      q: '',
      cursor: '',
      perPage: 30,
    })
      .then(res => {
        if (res.data.users) {
          setUsersLoading(false)
          setUsers(res.data.users)
        }
      })
      .catch(err => {
        setUsersLoading(false)
      })
  }

  /* 
Triggers user fetching based on `user` state:
- Monitors `user` in the dependency array to re-run the effect when `user` changes.
- Calls `getAllUsers` to fetch users if the `user.did` property is not an empty string.
- Ensures that user data is retrieved only when a valid `did` exists.
*/
  useEffect(() => {
    if (user.did !== '') {
      getAllUsers()
    }
  }, [user])

  /* 
Initializes post fetching on component mount:
- Checks if the `tab` state is set to `allPosts`.
- Resets the posts list (`setPosts([])`) and cursor (`setCursor('')`) to start fresh.
- Calls `fetchAllPosts` to load all posts starting from the initial cursor.
- This effect runs only once when the component mounts due to an empty dependency array.
*/
  useEffect(() => {
    if (tab == 'allPosts') {
      setPosts([])
      setCursor('')
      fetchAllPosts('')
    }
  }, [])

  return (
    <Page
      title="Personia"
      sidebar={isDesktop() || isTablet() ? <ProfileBox /> : <></>}
      sidebar2={
        isDesktop() ? (
          <>
            {user.did !== '' ? (
              <div id="scrollableDiv">
                <UsersBox
                  background={'navy90'}
                  users={users}
                  loading={usersLoading}
                  setLoading={loading => {}}
                  title={'People You May Know'}
                />
              </div>
            ) : null}
            <AdvertiseBox>
              <img
                loading={'lazy'}
                alt={''}
                src={require('../../assets/images/avatia.png')}
              />
              <h5>Avatia!</h5>
              <p>
                Turn your 2D photos into fully personalized 3D avatars with
                Avatia! Use your avatars for meetings, therapy sessions, and
                more. Start creating your avatar today!
              </p>
              <a href={'https://avatia.io'} target={'_blank'}>
                <CButton
                  fullWidth
                  background={'transparent'}
                  color={'green100'}
                  variant={'outlined'}
                  hoverColor={'navy100'}
                  backgroundHover={'green10'}
                >
                  Visit Avatia
                </CButton>
              </a>
            </AdvertiseBox>
            <AdvertiseBox>
              <img
                loading={'lazy'}
                alt={''}
                src={require('../../assets/images/embodia.jpg')}
              />
              <h5>Embodia!</h5>
              <p>
                Virtual meeting rooms with customizable environments and
                avatars! Interact with AI-powered characters, set avatars for
                both yourself and the AI you like. Start having daily
                conversations while tracking emotions!
              </p>
              <a href={'https://embodia.io'} target={'_blank'}>
                <CButton
                  fullWidth
                  background={'transparent'}
                  color={'green100'}
                  variant={'outlined'}
                  hoverColor={'navy100'}
                  backgroundHover={'green10'}
                >
                  Visit Embodia
                </CButton>
              </a>
            </AdvertiseBox>
            <AdvertiseBox>
              <img
                loading={'lazy'}
                alt={''}
                src={require('../../assets/images/centeria.jpeg')}
              />
              <h5>Centeria!</h5>
              <p>
                Explore Centeria and trade physical and digital assets as NFTs,
                all secured by transparent smart contracts.
              </p>
              <a href={'https://centeria.io'} target={'_blank'}>
                <CButton
                  fullWidth
                  background={'transparent'}
                  color={'green100'}
                  variant={'outlined'}
                  hoverColor={'navy100'}
                  backgroundHover={'green10'}
                >
                  Visit Centria
                </CButton>
              </a>
            </AdvertiseBox>
            <div style={{ marginTop: '60px' }}></div>
          </>
        ) : (
          <></>
        )
      }
    >
      {isMobile() && user.did !== '' && (
        <AdvertiseBox>
          <div className={'mobile'}>
            <img
              loading={'lazy'}
              alt={''}
              src={require('../../assets/images/create-article.png')}
            />
            <div className={'column'}>
              <p>
                sum dolor sit amet, consectetur trud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo con Duis aute irure do
              </p>
              <Link to={'/articles/new'}>
                <CButton
                  fullWidth
                  background={'transparent'}
                  color={'green100'}
                  variant={'outlined'}
                  hoverColor={'navy100'}
                  backgroundHover={'green10'}
                >
                  Create article
                </CButton>
              </Link>
            </div>
          </div>
        </AdvertiseBox>
      )}

      {user && user.did === '' ? null : (
        <TabBox>
          <CTabs
            value={tab}
            onChange={handleChangeViewType}
            key={1}
            $background={'navy60'}
            $activeBG={'navy60'}
          >
            <CTab
              label={'All'}
              id={'view-tab-all-posts'}
              aria-controls={'view-tabpanel-all-posts'}
              value={'allPosts'}
              disableTouchRipple
              $fullWidth
            />
            <CTab
              label={"Following's"}
              id={'view-tab-following-posts'}
              aria-controls={'view-tabpanel-following-post'}
              value={'followingPosts'}
              disableTouchRipple
              $fullWidth
            />
            <CTab
              label={'Mine'}
              id={'view-tab-mine-posts'}
              aria-controls={'view-tabpanel-mine-posts'}
              value={'minePosts'}
              disableTouchRipple
              $fullWidth
            />
          </CTabs>
        </TabBox>
      )}

      {loading ? (
        [1, 2, 3, 4].map(i => <PostBox loading={loading} key={i} type={2} />)
      ) : posts.length === 0 ? (
        <NothingFound
          icon="hourglass_disabled"
          title="No Posts Found"
          padding={'30px'}
        />
      ) : (
        <InfiniteScroll
          dataLength={posts.length}
          next={loadMoreHandler}
          hasMore={hasMore}
          loader={<PostBox loading={true} type={2} />}
          endMessage={
            <p
              style={
                isMobile || isDesktop
                  ? {
                      textAlign: 'center',
                      color: 'white',
                      padding: '20px',
                      background: '#201a31',
                    }
                  : null
              }
            >
              <b>No More Posts</b>
            </p>
          }
          scrollableTarget="scrollableDiv"
        >
          {[...posts]
            .sort(
              (x: any, y: any) =>
                new Date(y.createdAt).getTime() -
                new Date(x.createdAt).getTime()
            )
            .map((post, i) => {
              return <PostBox loading={false} post={post} key={i} type={2} />
            })}
        </InfiniteScroll>
      )}
    </Page>
  )
}
