import React, { type ReactElement } from 'react'
import { Controller } from 'react-hook-form'
import { DropZone, type DropZoneProps } from '../custom'

interface Props {
  controllerName: string
  controllerInstance: any
  controllerRules?: any
  errors: any
  onImageDelete?: (isDeleted: boolean) => void
}

export function ControllerDropZone(
  props: Omit<DropZoneProps, 'onSelect' | 'selectedFiles'> & Props
): ReactElement {
  const {
    controllerName,
    controllerInstance,
    controllerRules,
    onImageDelete,
    errors,
    ...other
  } = props

  const handleImageDelete = (isDeleted: boolean): void => {
    onImageDelete(isDeleted)
  }

  return (
    <Controller
      name={controllerName}
      control={controllerInstance}
      rules={controllerRules}
      render={({ field: { onChange, value } }) => (
        <DropZone
          selectedFiles={value}
          onImageDelete={handleImageDelete}
          onSelect={files => {
            onChange(files)
          }}
          helperText={errors[controllerName]?.message}
          maxSize={100}
          maxFiles={1}
          {...other}
        />
      )}
    />
  )
}

