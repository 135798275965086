import { getLinkPreview } from 'link-preview-js'

export default async function getURLMetaData(url: string) {
  let contentType: string
  try {
    const metaData = await getLinkPreview(url)
    if (metaData.mediaType === 'image') {
      contentType = metaData.mediaType
    } else if (metaData.mediaType === 'video') {
      contentType = metaData.mediaType
    }
    return { contentType }
  } catch (err) {
    console.error('Error fetching metadata:', err)
    return { contentType: 'unknown' } // Return 'unknown' on error
  }
}
