import React, { useEffect, useState } from 'react'
import { isDesktop } from '../../utils/detect-screen'
import { UsersBox } from '../../components/user'
import { Link } from 'react-router-dom'
import { CButton, CTab, CTabs } from '../../components/mui'
import { breakpoints } from '../../config/global-styles'
import styled from 'styled-components'
import { Page } from '../../components/structure'
import { ProfileBox } from '../../components/profile'
import { SearchAllUsers } from '../../apis/user.api'
import { ProfileModel } from '../../models/profile.model'
import { useAppSelector } from '../../redux/hooks'
import { NothingFound } from '../../components/custom'
import { PostBox } from '../../components/post/box'
import NotifBox from '../../components/notification/notifBox'

const TabBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  position: sticky;
  top: 0;
  z-index: 20;
color:white;
  > span {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.black80};
  }
`

const AdvertiseBox = styled.div`
  padding: 16px;
  background: ${props => props.theme.navy90};
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }

  > img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  > h5 {
    font-size: 22px;
    font-weight: 500;
    color: ${props => props.theme.white100};
    text-align: center;
    display: block;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  > p {
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.white100};
    text-align: justify;
    display: block;
    margin-bottom: 30px;
    line-height: 28px;
    margin-top: 30px;
  }
`
export default function NotificationPage() {
  const [users, setUsers] = useState<Array<ProfileModel>>([])
  const [usersLoading, setUsersLoading] = useState<boolean>()
  const user = useAppSelector(state => state.user)
  const [tab, setTab] = useState<'All' | 'Likes' | 'Messages' | 'Follow'>('All')

  useEffect(() => {
    setUsersLoading(true)
    SearchAllUsers({
      q: '',
      cursor: '',
      perPage: 10,
    })
      .then(res => {
        if (res.data.users) {
          setUsersLoading(false)
          setUsers(res.data.users)
        }
      })
      .catch(err => {
        setUsersLoading(false)
      })
  }, [])
  const handleChangeViewType = (event: any, newValue: any): void => {
    setTab(newValue)
    // setNoMoreDate(false);
    if (newValue == 'All') {
      setTab('All')
    } else if (newValue == 'Likes') {
      setTab('Likes')
    } else if (newValue == 'Messages') {
      setTab('Messages')
    } else if (newValue == 'Follow') {
      setTab('Follow')
    }
  }

  return (
    <Page
      title="Decentralized Social Media"
      sidebar={isDesktop() ? <ProfileBox /> : <></>}
      sidebar2={
        isDesktop() ? (
          <>
            {user.did !== '' ? (
              <div id="scrollableDiv">
                <UsersBox
                  background={'navy90'}
                  users={users}
                  loading={usersLoading}
                  setLoading={loading => {}}
                  title={'People You May Know'}
                />
              </div>
            ) : null}
            <AdvertiseBox>
              <img
                loading={'lazy'}
                alt={''}
                src={require('../../assets/images/create-article.png')}
              />
              <p>
                By sharing your content on the allostasis ecosystem, you can
                monetize your assets and make money. Just start sharing
                interesting content and let the ecosystem make money for you!
              </p>
              <Link to={'/articles/new'}>
                <CButton
                  fullWidth
                  background={'transparent'}
                  color={'green100'}
                  variant={'outlined'}
                  hoverColor={'navy100'}
                  backgroundHover={'green10'}
                >
                  Create Article
                </CButton>
              </Link>
            </AdvertiseBox>
            <AdvertiseBox>
              <img
                loading={'lazy'}
                alt={''}
                src={require('../../assets/images/wallet.png')}
              />
              <h5>Allostasis!</h5>
              <p>
                Unlock the world of NFTs and embrace a universe of creativity,
                uniqueness, and meaningful assets. Your journey to collectibles,
                art, and more begins here. Click now to explore, own, and make a
                difference!
              </p>
              <a href={'https://centeria.io'} target={'_blank'}>
                <CButton
                  fullWidth
                  background={'transparent'}
                  color={'green100'}
                  variant={'outlined'}
                  hoverColor={'navy100'}
                  backgroundHover={'green10'}
                >
                  Visit Centeria
                </CButton>
              </a>
            </AdvertiseBox>
            <div style={{ marginTop: '60px' }}></div>
          </>
        ) : (
          <></>
        )
      }
    >
      {user.did === '' ? null : (
        <TabBox>
          <CTabs
            value={tab}
            onChange={handleChangeViewType}
            key={1}
            $background={'navy60'}
            $activeBG={'navy60'}
          >
            <CTab
              label={'All'}
              id={'view-tabpanel-all-Notification'}
              aria-controls={'view-tabpanel-all-Notification'}
              value={'All'}
              disableTouchRipple
              $fullWidth
            />
            <CTab
              label={'Likes'}
              id={'view-tabpanel-likes-Notification'}
              aria-controls={'view-tabpanel-likes-Notification'}
              value={'Likes'}
              disableTouchRipple
              $fullWidth
            />
            <CTab
              label={'Messages'}
              id={'view-tabpanel-messages-Notification'}
              aria-controls={'view-tabpanel-messages-Notification'}
              value={'Messages'}
              disableTouchRipple
              $fullWidth
            />
            <CTab
              label={'Follow'}
              id={'view-tabpanel-follow-Notification'}
              aria-controls={'view-tabpanel-follow-Notification'}
              value={'Follow'}
              disableTouchRipple
              $fullWidth
            />
          </CTabs>
        </TabBox>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '-5px',
        }}
      >
        {tab === 'All' && (
          // <NotifBox />
          <div className="TabBox">All</div>
        )}
        {tab === 'Likes' && <div className="TabBox">Likes</div>}
        {tab === 'Messages' && <div className="TabBox">Messages</div>}
        {tab === 'Follow' && <div className="TabBox">Follow</div>}
      </div>
    </Page>
  )
}
