import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Avatar } from '@mui/material'
import _ from 'lodash'
import { CSkeleton } from '../mui'
import { useAppSelector } from '../../redux/hooks'
import { Link } from 'react-router-dom'
import { CSvgButton } from '../mui/SvgButton'
import { ReactComponent as UserAdd } from '../../assets/svg/add.svg'
import { ProfileModel } from '../../models/profile.model'
import { createChat, sendChatMessage } from '../../apis/chat.apis'
import { useGlobalStyleContext } from '../../contexts'

const Box = styled.div<{ $marginbottom: string }>`
  background: ${props => props.theme.gray70};
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 7px;
  margin-bottom: ${({ $marginbottom }) => $marginbottom};
  margin-top: ${({ $marginbottom }) => $marginbottom};

  > .grow {
    flex-grow: 1;
  }

  > a > .name {
    margin-left: 15px;
    text-decoration: none;

    > span:first-child {
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.white100};
      display: block;
      margin-bottom: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 120px;
    }

    > span:last-child {
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.white40};
      display: block;
      margin-bottom: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 100px;
      text-overflow: ellipsis;
    }

    > input {
      width: 200px;
      height: 20px;
      background: ${props => props.theme.black3};
      outline: none;
      border: none;
      font-size: 12px;
      font-weight: 400;
      color: ${props => props.theme.black60};
    }
  }
`

interface Props {
  user?: ProfileModel
  loading?: boolean
  index?: number
  followAction?: string
  onChangeList?: (users: any) => void
  roomType: string
  password?: string
  isRoomFull?: boolean
}

export function UserInfoRoomBox(props: Props): ReactElement {
  const { user, isRoomFull } = props
  const [isAddLoading, setIsAddLoading] = useState<boolean>(false)
  const { makeAlert } = useGlobalStyleContext()
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const addUser = async () => {
    // Check if the room is not full before adding a user
    if (!isRoomFull) {
      setButtonLoading(true); // Show loading state for the button
      setIsAddLoading(true); // Show loading state for adding user
      var message = ''; // Prepare the message
      message += '\n \n\n' + window.location.href; // Append room link to message
      message += '\n\n'; // Instruction to join

      // Create a chat for the user
      createChat(user.id).then(res => {
        // Send the chat message with the generated content
        sendChatMessage(res.data.chat.id, {
          content: encodeURIComponent(message), // Encode the message content
          messageType: 'text', // Specify the message type
        })
          .then(result => {
            if (result) {
              makeAlert('success', 'Message successfully sent'); // Show success alert
            }
            setIsAddLoading(false); // Reset loading state for adding user
            setButtonLoading(false); // Reset button loading state
          })
          .catch((error: any) => {
            setButtonLoading(false); // Reset button loading state on error
            console.error(error, 'error'); // Log error to console
            setIsAddLoading(false); // Reset adding user loading state on error
          });
      });
    } else {
      // Alert user if the room has reached the participant limit
      makeAlert(
        'error',
        'You’ve reached the limit of 4 participants for this video call. No additional participants can be added'
      );
    }
  };


  return (
    <Box $marginbottom={'10px'}>
      {props.loading ? (
        <CSkeleton width={40} height={40} borderradius={'20px'} />
      ) : (
        <Avatar
          src={`https://greenia.infura-ipfs.io/ipfs/${_.get(
            user,
            'avatar',
            ''
          )}`}
          alt={_.get(user, 'name', '')}
        />
      )}

      <Link to={'/u/' + user?.id}>
        <div className={'name'}>
          {props.loading ? (
            <>
              <CSkeleton width={100} height={10} borderradius={'20px'} />
              <CSkeleton width={100} height={10} borderradius={'20px'} />
            </>
          ) : (
            <>
              <span>{user?.displayName || 'New Face'}</span>
              <span>{_.get(user, 'did', '')}</span>
            </>
          )}
        </div>
      </Link>

      <div className={'grow'} />

      <CSvgButton
        loading={props.loading || buttonLoading}
        disabled={props.loading || buttonLoading}
        loadingColor={isAddLoading ? 'green100' : 'white100'}
        backgroundColor={isAddLoading ? 'gray70' : 'navy25'}
        backgroundColorHover={isAddLoading ? 'gray70' : 'navy25'}
        customColorHover={'transparent'}
        icon={<UserAdd />}
        onClick={addUser}
      />
    </Box>
  )
}
