import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { create as createIPFS, IPFSHTTPClient } from 'ipfs-http-client'
import { useAppSelector } from '../../redux/hooks'
import { SubmitHandler, useForm } from 'react-hook-form'
import { GlobalContext } from '../../contexts'
import { MyBlobToBuffer } from '../../utils/file'
import { Card, Page } from '../../components/structure'
import { CButton } from '../../components/mui'
import { Grid } from '@mui/material'
import {
  ControllerDropZone,
  ControllerEditorField,
  ControllerFileField,
  ControllerTextField,
} from '../../components/rhf'
import { ControllerTagField } from '../../components/rhf/tag-field'
import { ReactComponent as Publish } from '../../assets/svg/publish.svg'
import {
  ArticleCreateRequest,
  editArticle,
  findSingleArticle,
} from '../../apis/article.apis'
import { ArticleModel } from '../../models/article.model'
import styled from 'styled-components'
import { isMobile } from '../../utils/detect-screen'

const Flex = styled.div<{ marginRight: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  > *:first-child {
    flex-grow: 1;
    margin-right: ${({ marginRight }) => marginRight};
  }
`

const Length = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.gray50};
  display: block;
  text-decoration: none;
  margin-top: -40px;
  margin-right: 25px;
`

interface Inputs {
  description: string
  thumbnail: File[]
  file: File[]
  tag: string
  encryption: boolean
  visualAbstract: string
  abstract: string
  price: number
}

export function EditArticlePage(): ReactElement {
  const { id } = useParams()
  const navigate = useNavigate()
  const { makeAlert } = useContext(GlobalContext)
  const user = useAppSelector(state => state.user)
  const {
    control,
    handleSubmit,
    setError,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    defaultValues: {
      tag: '',
      encryption: false,
    },
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [article, setArticle] = useState<ArticleModel>()
  const [, setDecrypted] = useState<boolean>(false)

  /*
  Smoothly scrolls to the top of the page when the component mounts.
  Fetches the article based on the provided id, updates the state with the 
  article data, and stops the loading state. If fetching fails, redirects 
  the user to the articles page.
  */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })

    findSingleArticle(id)
      .then(res => {
        if (res.data.article) {
          setArticle(res.data.article)
        }
        setLoading(false)
      })
      .catch(() => {
        navigate('/articles')
      })
  }, [])

  /*
  When the article data is available, it resets the tags and populates 
  form fields (description, abstract, visual abstract) with the article's content. 
  It also updates the tags state by mapping through the article's tags.
  */
  useEffect(() => {
    if (article != null) {
      setTags([])
      setValue('description', decodeURIComponent(article.body ?? ''), {
        shouldValidate: true,
      })
      setValue('abstract', decodeURIComponent(article.abstract ?? ''), {
        shouldValidate: true,
      })
      setValue('visualAbstract', article.visualAbstract, {
        shouldValidate: true,
      })

      article.tags
        .filter(item => item !== '' && item !== null)
        .map(tag => {
          setTags(tags => [...tags, { text: tag }])
        })
    }
  }, [article])

  /*
  This block attempts to create an IPFS client using Infura API credentials. 
  If the process fails, it catches the error and sets the IPFS client to undefined.
  */
  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = createIPFS({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }

  /*
  The updateArticle function updates an existing article. It processes the thumbnail, uploads it to IPFS if present, and constructs the content of the article. 
  Once the content is ready, it sends an update request. If successful, it navigates to the updated article's page and shows a success message. 
  Errors during file upload or update process are handled with appropriate error messages.
  */
  const updateArticle = (fileId?: string) => {
    MyBlobToBuffer(
      watch('thumbnail') != null ? watch('thumbnail')[0] : undefined,
      async (err, buff) => {
        if (err) {
          setError('thumbnail', { message: 'File could not be uploaded' })
          setIsSubmitting(false)
        } else {
          let upload

          if (buff != null) {
            upload = await ipfs?.add(buff)
          }

          const thumbnailPath = upload ? upload.path : article?.attachment
          const externalUrl = fileId ? fileId : article?.externalURL
          const content: ArticleCreateRequest = {
            abstract: encodeURIComponent(watch('abstract')),
            body: encodeURIComponent(watch('description')),
            visualAbstract: watch('visualAbstract'),
            attachment: thumbnailPath,
            externalURL: externalUrl,
            tags: tags.map(x => x.text),
            isDeleted: false,
            isEncrypted: false,
            price: watch('price') ? watch('price').toString() : '0',
          }

          const update = editArticle(
            article?.id ?? '',
            content
            // profileID: user?.id ?? "",
          )

          if (update) {
            makeAlert('success', 'Article updated')
            setIsSubmitting(false)

            navigate('/articles/get/' + article?.id + '?update=true')
          } else {
            makeAlert('error', 'Error creating article with encryption')
            setIsSubmitting(false)
          }
        }
      }
    )
  }

  /*
  The onSubmit function handles the submission of the article form. It performs validation on the thumbnail, file, and description fields. 
  If the validation passes and IPFS is available, it processes and uploads files if present. The article is updated using the updateArticle function.
  Any errors during file upload or submission are caught and handled with relevant error messages. If validation fails, the submission is halted.
  */
  const onSubmit: SubmitHandler<Inputs> = async () => {
    setIsSubmitting(true)
    var goOn = true
    if (watch('thumbnail') != null && ipfs == null) {
      setError('thumbnail', { message: 'File could not be uploaded' })
      goOn = false
    }

    if (watch('file') != null && ipfs == null) {
      setError('file', { message: 'File could not be uploaded' })
      goOn = false
    }

    if (!watch('description')) {
      setError('description', {
        message:
          'lease ensure all required fields, indicated with (*), are filled before submitting.',
      })
      goOn = false
    }

    if (isValid || goOn) {
      try {
        let filePath
        if (watch('file')) {
          MyBlobToBuffer(
            watch('file') != null ? watch('file')[0] : undefined,
            async (err, buff) => {
              if (err) {
                setError('file', { message: 'File could not be uploaded' })
                setIsSubmitting(false)
              } else {
                if (buff != null) {
                  filePath = await ipfs?.add(buff)

                  updateArticle(filePath ? filePath.path : '')
                }
              }
            }
          )
        } else {
          updateArticle()
        }
      } catch (e) {
        makeAlert('error', 'Error updating article')
        setIsSubmitting(false)
      }
    } else {
      setIsSubmitting(false)
    }
  }

  const [tags, setTags] = useState<Array<{ text: string }>>([])

  /*
  The handleDelete function removes a tag from the tags array at the specified index.
  It creates a copy of the current tags, removes the tag at the given index, and updates the tags state with the modified array.
  */
  const handleDelete = (index: number) => {
    const _tags = [...tags]
    _tags.splice(index, 1)
    setTags(_tags)
  }

  /*
  The handleAddition function adds a new tag to the tags array.
  It validates the tag text and checks if the maximum of 10 tags has been reached. 
  If valid, the new tag is appended; otherwise, an error alert is shown.
  */
  const handleAddition = (tag: any) => {
    const chars = /[a-zA-Z0-9?><;,{}[\]\-_+=!@#$%\^&*|']/
    if (tag.text !== '' && chars.test(tag.text)) {
      if (tags.length <= 10) setTags(tags => [...tags, { text: tag.text }])
      else makeAlert('error', 'Tags input reached (MAX 10)')
    }
  }

  const [length, setLength] = useState<number>(0)

  /*
  The handleGetLength function updates the length of the input text.
  If the input is empty, it sets the length to 0; otherwise, it sets the length to the current text length.
  */
  const handleGetLength = (text: any) => {
    if (text.target.value === '') setLength(0)
    else setLength(text.target.value.length)
  }

  const [isImageDeleted, setIsImageDeleted] = useState<boolean>(false)

  const handleImageDelete = (isDeleted: boolean): void => {
    setIsImageDeleted(isDeleted)
  }

  return (
    <Page
      title={'Update Article'}
      sidebar={
        <div className={'back'}>
          <Link to={'/articles'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </Link>
        </div>
      }
      sidebar2={<></>}
    >
      <Card
        title={'Update Article'}
        footerAlignment={'row-reverse'}
        footer={[
          <CButton
            key={1}
            loading={isSubmitting}
            disabled={isSubmitting || loading}
            margin="0 15px 0 0"
            form={'update-article'}
            type={'submit'}
            backgroundHover={'navy25'}
            background={'navy25'}
            startIconSvg={<Publish />}
          >
            Edit Article
          </CButton>,
          <Link key={2} to="/articles">
            <CButton
              background={'gray40'}
              backgroundHover={'gray40'}
              color={'white100'}
              backgroundDisabled={'red60'}
            >
              Cancel
            </CButton>
          </Link>,
        ]}
      >
        <form
          className="form"
          id="update-article"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={2}
            sx={{
              '@media (max-width:1130px)': {
                width: '100%',
                marginLeft: '0px',
                '.MuiGrid-item': {
                  paddingLeft: '0px',
                  width: '100%',
                  '.description': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                },
              },
            }}
          >
            <Grid item md={12} sm={12}>
              <ControllerTextField
                controllerInstance={control}
                controllerName="visualAbstract"
                errors={errors}
                disabled={isSubmitting}
                label={'Title *'}
                placeholder={'The title of your article'}
                controllerRules={{
                  required: {
                    value: true,
                    message:
                      'Please ensure all required fields, indicated with (*), are filled before submitting.',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Please edit to 255 characters or fewer',
                  },
                }}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <Flex marginRight={'0'}>
                <ControllerTextField
                  controllerInstance={control}
                  onKeyDown={event => handleGetLength(event)}
                  controllerName="abstract"
                  rows={5}
                  multiline
                  errors={errors}
                  paddingBottom={'40px'}
                  disabled={isSubmitting}
                  label={'Abstract *'}
                  placeholder={'The abstract of your post'}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                    maxLength: {
                      value: 1000,
                      message: 'Please edit to 1000 characters or fewer',
                    },
                  }}
                />
                <Length>
                  <p className={'length'}>{length}/1000</p>
                </Length>
              </Flex>
            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerEditorField
                label={'Description'}
                placeholder={'Full article Description'}
                controllerName={'description'}
                controllerInstance={control}
                disabled={isSubmitting}
                // Height={isMobile?'710px':'600px'}
                controllerRules={{
                  required: {
                    value: true,
                    message:
                      'Please ensure all required fields, indicated with (*), are filled before submitting.',
                  },
                }}
                errors={errors}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerDropZone
                controllerInstance={control}
                controllerName="file"
                label={'Add attachment file (Optional)'}
                errors={errors}
                disabled={isSubmitting}
                maxSize={100}
                maxFiles={1}
                acceptedFiles={['*/*']}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerDropZone
                controllerInstance={control}
                controllerName="thumbnail"
                label={'Add An Image, Gif or Video (Optional)'}
                errors={errors}
                disabled={isSubmitting}
                onImageDelete={handleImageDelete}
                image={
                  isImageDeleted
                    ? 'https://greenia.infura-ipfs.io/ipfs/' + ''
                    : article?.attachment
                    ? 'https://greenia.infura-ipfs.io/ipfs/' +
                      article?.attachment
                    : ''
                }
                maxSize={100}
                maxFiles={1}
                acceptedFiles={[
                  'image/jpg',
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'video/mp4',
                ]}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerTagField
                controllerInstance={control}
                controllerName={`tag`}
                errors={errors}
                disabled={isSubmitting}
                label={'Title'}
                placeholder={'Tag (Optional)'}
                tags={tags.map((item: any, index: number) => ({
                  text: item.text,
                  id: index.toString(),
                }))}
                handleAddition={handleAddition}
                handleDelete={handleDelete}
              />
            </Grid>
          </Grid>
        </form>
      </Card>
    </Page>
  )
}
