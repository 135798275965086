import React, { useEffect, useState } from 'react'
import { WalletLog } from './wallet'
import { isDesktop } from '../../../utils/detect-screen'
import { CSvgButton } from '../../mui/SvgButton'
import { ReactComponent as CloseLogin } from '../../../assets/svg/close-login.svg'
import { Loading } from './loading'

interface Props {
  randomString: string
  onClose: () => void
}

const WalletIframe: React.FC<Props> = (props: Props) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL || ''
  const src = `${baseURL}/static/login/login-${props.randomString}?platform=personia`
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    localStorage.removeItem('token')
  }, [])

  return (
    <WalletLog width="100%">
      {loading ? (
        <Loading onClose={props.onClose}/>
      ) : (
        <CSvgButton
          icon={<CloseLogin />}
          disabled={false}
          onClick={props.onClose}
          customColor={'white100'}
          backgroundColorHover={'white'}
          backgroundColor="#140e26"
          style={{
            position: 'absolute',
            right: '0px',
            top: '0px',
            marginTop: '15px',
            marginRight: '15px',
            zIndex: '1000',
          }}
        />
      )}
      <iframe
        src={src}
        className="iframe-class"
        title="Wallet Iframe"
        onLoadedData={() => setLoading(false)}
        onLoad={() => setLoading(false)}
        style={{ height: isDesktop() ? '420px' : '93%' }}
      ></iframe>
    </WalletLog>
  )
}

export default WalletIframe
