import { Link, useNavigate } from 'react-router-dom'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { create as createIPFS, IPFSHTTPClient } from 'ipfs-http-client'
import { useAppSelector } from '../../redux/hooks'
import { Card, Page } from '../../components/structure'
import { CButton } from '../../components/mui'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Grid } from '@mui/material'
import {
  ControllerDropZone,
  ControllerEditorField,
  ControllerFileField,
  ControllerTextField,
} from '../../components/rhf'
import { GlobalContext } from '../../contexts'
import { MyBlobToBuffer } from '../../utils/file'
import { ControllerTagField } from '../../components/rhf/tag-field'
import { ReactComponent as Publish } from '../../assets/svg/publish.svg'
import { createArticle } from '../../apis/article.apis'
import styled from 'styled-components'
import { AlertContext } from '../../contexts/alert-context'
import { title } from 'process'
import FieldsErrorAlert from '../../components/structure/fieldsErrorAlert'
import { isMobile } from '../../utils/detect-screen'

const Flex = styled.div<{ marginRight: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  > *:first-child {
    flex-grow: 1;
    margin-right: ${({ marginRight }) => marginRight};
  }
`

const Length = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.gray50};
  display: block;
  text-decoration: none;
  margin-top: -40px;
  margin-right: 25px;
`

interface Inputs {
  description: string
  thumbnail: File[]
  file: File[]
  tag: string
  encryption: boolean
  title: string
  shortDescription: string
  price: number
}

export function NewArticlePage(): ReactElement {
  const user = useAppSelector(state => state.user)
  const navigate = useNavigate()
  const { makeAlert } = useContext(GlobalContext)

  /*
  The useEffect hook scrolls the window to the top-left corner smoothly 
  when the component mounts, ensuring a clean viewing experience for users.
  */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const {
    control,
    handleSubmit,
    setError,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    defaultValues: {
      tag: '',
      encryption: false,
    },
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  // Initialize IPFS
  let ipfs: IPFSHTTPClient | undefined
  try {
    ipfs = createIPFS({
      url: 'https://ipfs.infura.io:5001/api/v0',
      headers: {
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID +
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET
          ),
      },
    })
  } catch (error) {
    ipfs = undefined
  }

  /*
  The createArticleFunc function uploads a thumbnail to IPFS and creates a new article 
  with the provided data. It handles errors for file uploads and submission, displaying 
  appropriate alerts for success or failure.
  */
  const createArticleFunc = (filePath?: string, data?: any) => {
    MyBlobToBuffer(
      watch('thumbnail') != null ? watch('thumbnail')[0] : undefined,
      async (err, buff) => {
        setIsSubmitting(true)
        if (err) {
          setError('thumbnail', { message: 'File could not be uploaded' })
          setIsSubmitting(false)
        } else {
          let upload

          if (buff != null) {
            upload = await ipfs?.add(buff)
          }

          const thumbnailPath = upload?.path ?? ''
          const externalUrl = filePath ?? ''
          const content = {
            abstract: encodeURIComponent(data.shortDescription),
            body: encodeURIComponent(data.description),
            visualAbstract: data.title,
            attachment: thumbnailPath,
            externalURL: externalUrl,
            tags: tags.map(x => x.text),
            isDeleted: false,
            isEncrypted: false,
            price: data.price ?? 0,
          }

          await createArticle(content)
            .then(result => {
              setIsSubmitting(false)
              makeAlert('success', 'Article Created')
              navigate('/articles')
            })
            .catch(error => {
              makeAlert('error', 'Error creating article')
              setIsSubmitting(false)
            })
        }
      }
    )
  }

  /*
  The onSubmit function validates form inputs, checks if files can be uploaded to IPFS, 
  and calls createArticleFunc to create a new article if validation passes. 
  It manages submission states and error handling throughout the process.
  */
  const onSubmit: SubmitHandler<Inputs> = async data => {
    setIsSubmitting(true)
    var goOn = true

    if (watch('thumbnail') != null && ipfs == null) {
      setError('thumbnail', { message: 'Thumbnail could not be uploaded' })
      goOn = false
    }

    if (watch('file') != null && ipfs == null) {
      setError('file', { message: 'File could not be uploaded' })
      goOn = false
    }

    // if (!watch('description')) {
    //   setError('description', {
    //     message: ' ',
    //   })
    //   goOn = false
    // }

    if (isValid && goOn) {
      try {
        let filePath
        if (watch('file')) {
          MyBlobToBuffer(
            watch('file') != null ? watch('file')[0] : undefined,
            async (err, buff) => {
              if (err) {
                setError('file', { message: 'File could not be uploaded' })
                setIsSubmitting(false)
              } else {
                if (buff != null) {
                  filePath = await ipfs?.add(buff)
                  createArticleFunc(filePath?.path ?? '', data)
                }
              }
            }
          )
        } else {
          createArticleFunc('', data)
        }
      } catch (e) {
        console.error('Error creating article:', e)
        makeAlert('error', 'Error creating article')
        setIsSubmitting(false)
      }
    } else {
      setIsSubmitting(false)
    }
  }

  const [tags, setTags] = useState<Array<{ text: string }>>([])

  /*
  The handleDelete function removes a tag from the tags array at the specified index.
  It creates a copy of the current tags, removes the tag at the given index, and updates the tags state with the modified array.
  */
  const handleDelete = (index: number) => {
    const _tags = [...tags]
    _tags.splice(index, 1)
    setTags(_tags)
  }

  /*
  The handleAddition function adds a new tag to the tags array.
  It validates the tag text and checks if the maximum of 10 tags has been reached. 
  If valid, the new tag is appended; otherwise, an error alert is shown.
  */
  const handleAddition = (tag: any) => {
    const chars = /[a-zA-Z0-9?><;,{}[\]\-_+=!@#$%\^&*|']/
    if (tag.text !== '' && chars.test(tag.text)) {
      if (tags.length <= 10) setTags(tags => [...tags, { text: tag.text }])
      else makeAlert('error', 'Tags input reached (MAX 10)')
    }
  }

  /*
  The handleResetForm function resets the form fields to their initial state 
  and clears any tags that have been added, ensuring a fresh start for the user.
  */
  const handleResetForm = () => {
    reset()
    setTags([])
  }

  /*
  The handleGetLength function updates the length of the input text.
  If the input is empty, it sets the length to 0; otherwise, it sets the length to the current text length.
  */
  const [length, setLength] = useState<number>(0)
  const handleGetLength = (text: any): void => {
    setTimeout(() => {
      setLength(text.target.value.length)
    }, 0)
  }
  //  const handleGetLength = (text: string) => {
  //     setLength(text.length)
  //   }

  const alertContext = useContext(AlertContext)
  if (!alertContext) {
    throw new Error('FieldsErrorAlert must be used within an AlertProvider')
  }

  const { setShowAlert } = alertContext

  const titleValue = useWatch({ control, name: 'title' })
  const abstractValue = useWatch({ control, name: 'shortDescription' })

  const handlePublishNewArticle = () => {
    if (
      titleValue == '' ||
      abstractValue == '' ||
      titleValue == undefined ||
      abstractValue == undefined
    ) {
      setShowAlert(true)
    }
  }

  const [isImageDeleted, setIsImageDeleted] = useState<boolean>(false)

  const handleImageDelete = (isDeleted: boolean): void => {
    setIsImageDeleted(isDeleted)
  }

  return (
    <Page
      title={'New Article'}
      sidebar={
        <div className={'back'}>
          <Link to={'/'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </Link>
        </div>
      }
      sidebar2={<></>}
    >
      <Card
        title={'Create Article'}
        footerAlignment={'row-reverse'}
        footer={[
          <CButton
            key={1}
            loading={isSubmitting}
            disabled={isSubmitting}
            margin="0 15px 0 0"
            form={'create-article'}
            background={'navy25'}
            backgroundHover={'navy25'}
            color={'white100'}
            type={'submit'}
            startIconSvg={<Publish />}
            onClick={handlePublishNewArticle}
          >
            Publish Article
          </CButton>,
          <CButton
            background={'gray40'}
            backgroundHover={'gray40'}
            color={'white100'}
            backgroundDisabled={'gray40'}
            onClick={handleResetForm}
          >
            Clear
          </CButton>,
        ]}
      >
        <form
          className="form"
          id="create-article"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={2}
            sx={{
              '@media (max-width:1130px)': {
                width: '100%',
                marginLeft: '0px',
                '.MuiGrid-item': {
                  paddingLeft: '0px',
                  width: '100%',
                  '.description': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                },
              },
            }}
          >
            <Grid item md={12} sm={12}>
              <ControllerTextField
                controllerInstance={control}
                controllerName="title"
                errors={errors}
                disabled={isSubmitting}
                label={'Title *'}
                placeholder={'The title of your article'}
                controllerRules={{
                  required: {
                    value: true,
                    message: ' ',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Please edit to 255 characters or fewer',
                  },
                }}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <Flex marginRight={'0'}>
                <ControllerTextField
                  controllerInstance={control}
                  onKeyDown={handleGetLength}
                  controllerName="shortDescription"
                  rows={5}
                  multiline
                  errors={errors}
                  paddingBottom={'40px'}
                  disabled={isSubmitting}
                  label={'Abstract *'}
                  placeholder={'The abstract of your article'}
                  controllerRules={{
                    required: {
                      value: true,
                      message: ' ',
                    },
                    maxLength: {
                      value: 1000,
                      message: 'Please edit to 1000 characters or fewer',
                    },
                  }}
                />
                <Length>
                  <p className={'length'}>{length}/1000</p>
                </Length>
              </Flex>
            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerEditorField
                label={'Description'}
                placeholder={'Full Article Description'}
                controllerName="description"
                controllerInstance={control}
                disabled={isSubmitting}
                errors={errors}
                // Height={isMobile?'710px':'600px'}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerDropZone
                controllerInstance={control}
                controllerName="file"
                label={'Add attachment file (Optional)'}
                onImageDelete={handleImageDelete}
                errors={errors}
                disabled={isSubmitting}
                maxSize={100}
                maxFiles={1}
                acceptedFiles={['*/*']}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerDropZone
                controllerInstance={control}
                controllerName="thumbnail"
                onImageDelete={handleImageDelete}
                label={'Add An Image, Gif or Video (Optional)'}
                errors={errors}
                disabled={isSubmitting}
                maxSize={100}
                maxFiles={1}
                acceptedFiles={[
                  'image/jpg',
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'video/mp4',
                  'image/webp',
                  'image/bmp',
                  'image/svg+xml',
                  'image/heif',
                ]}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerTagField
                controllerInstance={control}
                controllerName={`tag`}
                errors={errors}
                disabled={isSubmitting}
                label={'Title'}
                placeholder={'Tag (Optional)'}
                tags={tags.map((item: any, index: number) => ({
                  text: item.text,
                  id: index.toString(),
                }))}
                handleAddition={handleAddition}
                handleDelete={handleDelete}
              />
            </Grid>
          </Grid>
        </form>
      </Card>
    </Page>
  )
}
