import { useNavigate, useParams } from 'react-router-dom'
import React, { ReactElement, useEffect, useState } from 'react'
import { Card, Page } from '../../components/structure'
import { CButton } from '../../components/mui'
import { PostBox } from '../../components/post/box'
import { NothingFound } from '../../components/custom'
import { ArticleBox } from '../../components/article/box'
import { ArticleModel } from '../../models/article.model'
import { findAllArticles } from '../../apis/article.apis'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isDesktop, isMobile } from '../../utils/detect-screen'

export function SearchArticlePage(): ReactElement {
  const { tag } = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [articles, setArticles] = useState<Array<ArticleModel>>([])
  const [noMoreData] = useState<boolean>(false)
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [cursor, setCursor] = useState<string>('')
  const [hasMore, setHasMore] = useState<boolean>(true)

  /*
   * Scrolls to the top of the page smoothly upon component mount.
   * Fetches all posts by calling fetchAllPosts.
   */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })

    fetchAllArticles('')
  }, [])

  /*
   * Fetches a paginated list of articles based on the current cursor and search tag.
   * Updates the articles state and pagination cursor if new articles are retrieved.
   * Manages loading states for better user feedback during the fetch operation.
   */
  const fetchAllArticles = (_cursor: string) => {
    // setLoading(true)

    findAllArticles({
      numberPerPage: 5,
      cursor: _cursor,
      search: { q: tag, profileIDs: [''] },
    })
      .then(res => {
        if (res && res.data.articles.length > 0) {
          // setCursor(res.data.cursor)
          setArticles(articles => [...articles, ...res.data.articles])
          setCursor(res.data.cursor)
          // Check if the cursor has not changed or fewer posts are returned
          if (res.data.articles.length < 5) {
            setHasMore(false)
          } else {
            setHasMore(true)
          }
        } else {
          setHasMore(false)
        }

        setPaginationLoading(false)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Page
      title={'Article By Tag Page'}
      sidebar={
        <>
          <div className={'back'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              onClick={() => navigate(-1)}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </div>
        </>
      }
      sidebar2={<></>}
    >
      <Card title={'Articles by tag: ' + tag}>
        <div id="scrollableDiv">
          {loading ? (
            [1, 2, 3, 4].map(i => (
              <ArticleBox loading={loading} key={i} type={2} />
            ))
          ) : articles.length === 0 ? (
            <NothingFound
              icon="hourglass_disabled"
              title="No Articles Found"
              padding={'30px'}
            />
          ) : (
            <InfiniteScroll
              dataLength={articles.length}
              next={() => fetchAllArticles(cursor)}
              hasMore={hasMore}
              loader={<ArticleBox loading={true} type={2} />}
              endMessage={
                <p
                  style={
                    isMobile || isDesktop
                      ? {
                          textAlign: 'center',
                          color: 'white',
                          padding: '20px',
                          background: '#160f2b',
                          borderRadius: '8px',
                          fontSize: '13px',
                        }
                      : null
                  }
                >
                  <b>No More Articles</b>
                </p>
              }
            >
              <div>
                {articles.map((article, i) => {
                  return (
                    <ArticleBox
                      loading={loading}
                      article={article}
                      key={i}
                      type={2}
                    />
                  )
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </Card>
    </Page>
  )
}
