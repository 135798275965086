/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export interface StyledInputType {
  $background: string
  $height: string
  $color: string
  $border: string
}

const Input = styled.div<StyledInputType>`
  display: flex;
  border: 1px solid ${({ theme, $border }) => theme[$border]};
  border-radius: 8px;
  align-items: center;
  width: 100%;

  > label {
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: ${({ theme, $color }) => theme[$color]};
  }

  > input {
    width: 100%;
    outline: none;
    height: ${({ $height }) => $height}px;
    color: ${({ theme, $color }) => theme[$color]};
    background: ${({ theme, $background }) => theme[$background]};
    padding: 0 10px;
    border: none;
  }

  > .material-symbols-outlined {
    background: ${({ theme, $background }) => theme[$background]};
    color: ${({ theme, $color }) => theme[$color]};
    height: ${({ $height }) => $height}px;
    padding: 5px;
  }
`

interface Props {
  name: string
  placeholder: string
  label: string
  onChange?: (value: any) => void
  onClick?: () => void
  disabled?: boolean
  type?: 'text' | 'email' | 'password' | 'file'
  value?: any
  icon?: string
  background?: string
  height?: string
  color?: string
  border?: string
  caretColor?: any
  setValue?: (value: string) => void
}

export function MyInput(props: Props): ReactElement {
  const {
    name,
    label,
    placeholder,
    onChange,
    onClick,
    disabled = false,
    type = 'text',
    value,
    icon,
    background = 'white100',
    height = '35',
    color = 'white100',
    border = 'black100',
    caretColor = 'white',
    setValue = () => {},
  } = props

  const [isClicked, setIsClicked] = useState<boolean>(false)
  const boxRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        setIsClicked(false)
        setValue('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setValue])

  return (
    <Input
      $background={background}
      $height={height}
      $border={border}
      $color={color}
      onClick={() => {
        if (onClick) {
          onClick()
        }
        setIsClicked(true)
      }}
      ref={boxRef}
      style={{
        border: `1px solid ${isClicked ? 'white' : '#635f6f'}`,
      }}
    >
      <label>{label}</label>
      <input
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={'off'}
        onChange={e => {
          if (onChange != null) {
            if (type === 'file') {
              onChange(e.target.files)
            } else {
              onChange(e.target.value)
            }
          }
        }}
        type={type}
        value={value}
        style={{ caretColor: caretColor }}
      />
      {icon != null ? (
        <span className="material-symbols-outlined" style={{ color: 'white' }}>
          {icon}
        </span>
      ) : null}
    </Input>
  )
}
