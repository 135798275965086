import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CSkeleton } from '../mui'
import YouTube from 'react-youtube'
import { getLinkPreview } from 'link-preview-js'
import { isDesktop, isMobile } from '../../utils/detect-screen'

const isValidUrlProp = (props, propName, componentName) => {
  if (!props) {
    return new Error(`Required parameter URL was not passed.`)
  }
  if (!isValidUrl(props[propName])) {
    return new Error(
      `Invalid prop '${propName}' passed to '${componentName}'. Expected a valid url.`
    )
  }
}

const isValidUrl = url => {
  const regex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
  const validUrl = regex.test(url)
  return validUrl
}

interface props {
  url?: string
  width?: string
  margintop?: string
  marginbottom?: string
  marginRight?: string
  marginLeft?: string
  height?: string
  onClick?: any
  customDomain?: any
  onValueChange?: any
}

export default function LinkPreview(props) {
  const [loading, setLoading] = useState(true)
  const [preview, setPreviewData] = useState({
    url: undefined,
    contentType: undefined,
  })
  const [isUrlValid, setUrlValidation] = useState(false)

  const {
    url,
    width,
    height,
    maxwidth,
    margintop,
    marginbottom,
    marginRight,
    marginLeft,
    onClick,
    customDomain,
    onValueChange,
  } = props

  const style = {
    width,
    maxwidth,
    margintop,
    marginbottom,
    marginRight,
    marginLeft,
  }

  const [isVideo, setIsVideo] = useState(false)
  const [videoId, setVideoId] = useState('')

  function matchYoutubeUrl(url: string) {
    var p =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    if (url.match(p)) {
      return url.match(p)[1]
    }
    return false
  }

  const checkYoutube = (url: string) => {
    if (matchYoutubeUrl(url)) {
      setIsVideo(true)
      setShowVideo(true)

      if (onValueChange) {
        onValueChange(true)
      }

      var r: string | any[],
        rx =
          /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/
      r = url.match(rx)
      setVideoId(r.length > 0 ? r[1] : '')
      return true
    }
    setIsVideo(false)
    if (onValueChange) {
      onValueChange(false) // Send the isVideo value to the parent
    }
    return false
  }

  useEffect(() => {
    ;(async () => {
      // if ((url + '').startsWith('https://greenia.infura-ipfs.io')) {
      try {
        const metaData = await getLinkPreview(url, {
          proxyUrl: 'https://cors.allostasis.shop/',
          timeout: 1000,
        })
        console.log(metaData)
        setPreviewData({
          url: metaData['images']
            ? metaData['images'][0]
            : metaData['videos']
            ? metaData['videos'][0]
            : metaData.mediaType !== 'website'
            ? metaData.url.replace('https://cors.allostasis.shop/', '')
            : '',
          contentType: metaData['images']
            ? 'image'
            : metaData['videos']
            ? 'video'
            : metaData.mediaType,
        })

        setLoading(false)
      } catch (error) {
        console.error(error)
      }
      // }
    })()
  }, [url])

  useEffect(() => {
    function fetchData() {
      if (!checkYoutube(url)) {
        const fetch = window.fetch
        if (isValidUrl(url)) {
          setUrlValidation(true)
        } else {
          return null
        }
        setLoading(true)
      } else {
        setLoading(false)
      }
    }

    fetchData()
  }, [url])

  const [showVideo, setShowVideo] = useState<boolean>(true)

  return url !== '' ? (
    loading ? (
      <div
        style={{
          width: '100%',
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CSkeleton
          width={isMobile() ? '100%' : `${width}`}
          height={isMobile() ? '90%' : `${height}`}
          borderradius={'8px'}
        />
      </div>
    ) : isVideo ? (
      <div style={{ width: `${width}`, marginTop: '10px', height: '400px' }}>
        <YouTube
          style={{ width: '100%', height: showVideo ? '100%' : 0 }}
          iframeClassName={'w-100'}
          videoId={videoId}
          onError={error => {
            console.log(error)
            setShowVideo(false)
          }}
        />
      </div>
    ) : (
      preview && (
        <>
          {preview.contentType === 'image' && preview.url !== undefined && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '400px',
              }}
            >
              <img
                loading={'lazy'}
                src={preview.url}
                style={{
                  display: 'flex',
                  width: isMobile() ? '100%' : `${width}`,
                  borderRadius: '8px',
                  height: isMobile() ? '90%' : `${height}`,
                }}
                alt={preview.contentType}
              />
            </div>
          )}
          {preview.contentType === 'video' && preview.url !== undefined && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '400px',
              }}
            >
              <video
                src={preview.url}
                controls
                style={{
                  width: isMobile() ? '100%' : `${width}`,
                  borderRadius: '8px',
                  height: isMobile() ? '90%' : `${height}`,
                  objectFit: 'fill',
                }}
              >
                <source src={preview.url} type="video/mp4" />
              </video>
            </div>
          )}
        </>
      )
    )
  ) : null
}

LinkPreview.defaultProps = {
  onClick: () => {},
  width: '90%',
  maxwidth: '700px',
  margintop: '18px',
  marginbottom: '18px',
  marginRight: 'auto',
  marginLeft: 'auto',
  customDomain: 'https://lpdg-server.azurewebsites.net/parse/link',
}

LinkPreview.propType = {
  url: isValidUrlProp,
  onClick: PropTypes.func,
  render: PropTypes.func,
  width: PropTypes.string,
  maxwidth: PropTypes.string,
  margintop: PropTypes.string,
  marginbottom: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  customDomain: PropTypes.string,
  onValueChange: PropTypes.func,
}
