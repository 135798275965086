import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { create as createIPFS, IPFSHTTPClient } from 'ipfs-http-client'
import { SubmitHandler, useForm } from 'react-hook-form'
import { GlobalContext, useGlobalStyleContext } from '../../contexts'
import { MyBlobToBuffer } from '../../utils/file'
import { Card, Page } from '../../components/structure'
import { CButton } from '../../components/mui'
import { Grid } from '@mui/material'
import { ControllerDropZone, ControllerTextField } from '../../components/rhf'
import { ControllerTagField } from '../../components/rhf/tag-field'
import { ReactComponent as Publish } from '../../assets/svg/publish.svg'
import {
  PostCreateRequest,
  editPost,
  findSinglePost,
} from '../../apis/post.apis'
import { PostModel } from '../../models/post.model'
import styled from 'styled-components'
import { AddResult } from 'ipfs-core-types/dist/src/root'

interface Inputs {
  description: string
  thumbnail: File[]
  tag: string
}

const Flex = styled.div<{ marginRight: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  > *:first-child {
    flex-grow: 1;
    margin-right: ${({ marginRight }) => marginRight};
  }
`

const Length = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.gray50};
  display: block;
  text-decoration: none;
  margin-top: -40px;
  margin-right: 25px;
`

export function EditPostPage(): ReactElement {
  const { id } = useParams()
  const navigate = useNavigate()
  const { makeAlert } = useContext(GlobalContext)
  const {
    control,
    handleSubmit,
    setError,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    defaultValues: {
      tag: '',
    },
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [post, setPost] = useState<PostModel>()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })

    findSinglePost(id)
      .then(res => {
        if (res.data.post) {
          setPost(res.data.post)
        }
        setLoading(false)
      })
      .catch(() => {
        navigate('/posts')
      })
  }, [])

  useEffect(() => {
    if (post != null) {
      setTags([])
      setValue('description', decodeURIComponent(post.body ?? ''), {
        shouldValidate: true,
      })
      post.tags
        ?.filter(item => item !== '')
        .map(tag => {
          setTags(tags => [...tags, { text: tag }])
        })
    }
  }, [post])

  // Initialize an IPFS HTTP client
  let ipfs: IPFSHTTPClient | undefined

  try {
    // Attempt to create an IPFS instance using Infura's API
    ipfs = createIPFS({
      url: 'https://ipfs.infura.io:5001/api/v0', // IPFS endpoint URL
      headers: {
        // Set up authorization headers with Infura credentials
        authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_INFURA_PROJECT_ID + // Infura project ID
              ':' +
              process.env.REACT_APP_INFURA_API_KEY_SECRET // Infura API key secret
          ),
      },
    })
  } catch (error) {
    // If an error occurs during IPFS initialization, set ipfs to undefined
    ipfs = undefined
  }

  // Function to handle form submission, specifically for creating or updating a post
  const onSubmit: SubmitHandler<Inputs> = async () => {
    setIsSubmitting(true) // Set submitting state to true to indicate that form is being submitted

    // Check if there's a thumbnail but no IPFS instance, set an error for the thumbnail field
    if (watch('thumbnail') != null && ipfs == null) {
      setError('thumbnail', { message: 'File could not be uploaded' })
    }

    // Proceed only if form validation is successful
    if (isValid) {
      try {
        // Convert the uploaded thumbnail to a buffer
        MyBlobToBuffer(
          watch('thumbnail') != null ? watch('thumbnail')[0] : undefined,
          async (err, buff) => {
            if (err) {
              // Handle error if buffer conversion fails
              setError('thumbnail', { message: 'File could not be uploaded' })
              setIsSubmitting(false) // Reset submitting state
            } else {
              let upload

              // If buffer exists, upload it to IPFS
              if (buff != null) {
                upload = await ipfs?.add(buff)
              }

              // Set the thumbnail path, or fallback to the existing attachment (if editing)
              const thumbnailPath = isImageDeleted
                ? ''
                : upload
                ? upload.path
                : post?.attachment

              // Create post content with description, attachment, and tags
              const content: PostCreateRequest = {
                body: encodeURIComponent(watch('description')), // Encode the post description
                attachment: thumbnailPath, // Set the thumbnail/attachment path
                tags: tags.map(item => item.text), // Map tags to their text value
              }

              // Send request to update the post with the given content
              const update = editPost(post?.id ?? '', content)

              if (update) {
                // On successful update, show success alert and reset submitting state
                makeAlert('success', 'Post updated')
                setIsSubmitting(false)
                // Navigate to the updated post's page
                navigate('/posts/get/' + post?.id + '?update=true')
              } else {
                // Handle error if post update fails
                makeAlert('error', 'Error creating post with encryption')
                setIsSubmitting(false) // Reset submitting state
              }
            }
          }
        )
      } catch (e) {
        // Handle any unexpected error during post update
        makeAlert('error', 'Error updating post')
        setIsSubmitting(false) // Reset submitting state
      }
    } else {
      // Reset submitting state if form is invalid
      setIsSubmitting(false)
    }
  }

  const [tags, setTags] = useState<Array<{ text: string }>>([])

  const handleDelete = (index: number) => {
    const _tags = [...tags]
    _tags.splice(index, 1)
    setTags(_tags)
  }

  // Function to handle the addition of a new tag
  const handleAddition = (tag: any) => {
    // Regular expression to validate tag characters
    const chars = /[a-zA-Z0-9?><;,{}[\]\-_+=!@#$%\^&*|']/

    // Check if the tag text is not empty and contains valid characters
    if (tag.text !== '' && chars.test(tag.text)) {
      // If the current number of tags is less than or equal to 10, add the new tag
      if (tags.length <= 10) {
        setTags(tags => [...tags, { text: tag.text }])
      } else {
        // If the limit of 10 tags is reached, show an error alert
        makeAlert('error', 'Tags input reached (MAX 10)')
      }
    }
  }

  const [length, setLength] = useState<number>(0)
  const handleGetLength = (text: any) => {
    if (text.target.value === '') setLength(0)
    else setLength(text.target.value.length)
  }

  
  const [isImageDeleted, setIsImageDeleted] = useState<boolean>(false)

  const handleImageDelete = (isDeleted: boolean): void => {
    setIsImageDeleted(isDeleted)
  }

  return (
    <Page
      title={'Update Post'}
      sidebar={
        <div className={'back'}>
          <Link to={'/'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </Link>
        </div>
      }
      sidebar2={<></>}
    >
      <Card
        title={'Update Post'}
        footerAlignment={'row-reverse'}
        footer={[
          <CButton
            key={1}
            loading={isSubmitting}
            disabled={isSubmitting || loading}
            margin="0 15px 0 0"
            form={'update-post'}
            type={'submit'}
            backgroundHover={'navy25'}
            background={'navy25'}
            startIconSvg={<Publish />}
          >
            Edit Post
          </CButton>,
          <Link key={2} to="/">
            <CButton
              background={'gray40'}
              backgroundHover={'gray40'}
              color={'white100'}
              backgroundDisabled={'red60'}
            >
              Cancel
            </CButton>
          </Link>,
        ]}
      >
        <form
          className="form"
          id="update-post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={2}
            sx={{
              '@media (max-width:1130px)': {
                width: '100%',
                marginLeft: '0px',
                '.MuiGrid-item': {
                  paddingLeft: '0px',
                  width: '100%',
                  '.description': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                },
              },
            }}
          >
            <Grid item md={12} sm={12}>
              <Flex marginRight={'0'}>
                <ControllerTextField
                  controllerInstance={control}
                  controllerName="description"
                  onKeyDown={event => handleGetLength(event)}
                  rows={10}
                  multiline
                  errors={errors}
                  disabled={isSubmitting || loading}
                  label={'Description'}
                  placeholder={'The full content of your post'}
                  controllerRules={{
                    required: {
                      value: true,
                      message:
                        'Please ensure all required fields, indicated with (*), are filled before submitting.',
                    },
                    maxLength: {
                      value: 1000,
                      message: 'Please edit to 1000 characters or fewer',
                    },
                  }}
                />
                <Length>
                  <p className={'length'}>{length}/1000</p>
                </Length>
              </Flex>
            </Grid>

            <Grid item md={12} sm={12}>
              <ControllerDropZone
                controllerInstance={control}
                onImageDelete={handleImageDelete}
                controllerName="thumbnail"
                label={'Add An Image, Gif or Video (Optional)'}
                image={
                  isImageDeleted
                    ? 'https://greenia.infura-ipfs.io/ipfs/' + ''
                    : post?.attachment
                    ? 'https://greenia.infura-ipfs.io/ipfs/' + post?.attachment
                    : ''
                }
                type={'post'}
                errors={errors}
                disabled={isSubmitting}
                acceptedFiles={[
                  'image/jpg',
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'video/mp4',
                ]}
              />
            </Grid>

            <Grid item md={12} sm={12} key={1}>
              <ControllerTagField
                controllerInstance={control}
                controllerName={`tag`}
                errors={errors}
                disabled={isSubmitting}
                label={'Title'}
                placeholder={'Tag (Optional)'}
                tags={tags.map((item: any, index: number) => ({
                  text: item.text,
                  id: index.toString(),
                }))}
                handleAddition={handleAddition}
                handleDelete={handleDelete}
              />
            </Grid>
          </Grid>
        </form>
      </Card>
    </Page>
  )
}
