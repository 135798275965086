import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../redux/hooks'
import { MyInput } from '../custom/input'
import { UserInfoBox } from './list'
import { breakpoints } from '../../config/global-styles'
import { SearchAllUsers } from '../../apis/user.api'
import { ProfileModel } from '../../models/profile.model'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loading } from '../auth/login-wallet-connect-steps/loading'
import { colors } from '@mui/material'

const UserBox = styled.div<{ background: string }>`
  background: ${({ theme, background }) => theme[background]};
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;

  > .header {
    border-bottom: 1px solid ${props => props.theme.black60};
    margin: 0 auto 0 auto;
    padding: 10px 0 0 0;

    > p {
      font-size: 14px;
      font-weight: 400;
      color: ${props => props.theme.green100};
      border-bottom: 2px solid ${props => props.theme.green100};
      text-align: center;
      line-height: 28px;
      width: 47%;
      align-self: center;
      margin: 0 auto 0 auto;
      padding: 5px;

      @media only screen and (min-width: ${breakpoints.minDesktop}) and (max-width: ${breakpoints.maxDesktop}) {
        width: 70%;
      }
    }
  }

  > .body {
    padding: 20px;

    .users {
      height: 400px;
      overflow-y: auto;
      margin-top: 10px;

      ::-webkit-scrollbar {
        background: ${props => props.theme.gray70};
        width: 8px;
        margin-left: 10px;
      }

      ::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.gray80};
        border-radius: 10px;
      }
    }
  }
`

interface Props {
  users?: Array<ProfileModel>
  title: string
  loading: boolean
  background: string
  setLoading: (loading: boolean) => void
}

export function UsersBox(props: Props): ReactElement {
  const me = useAppSelector(state => state.user)
  const [searchedUsers, setSearchedUsers] = useState<Array<ProfileModel>>([])
  const [searchText, setSearchText] = useState<string>('')
  const [displayedUsers, setDisplayedUsers] = useState<Array<ProfileModel>>([])
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [cursor, setCursor] = useState<string>('') // Track the cursor for pagination

  useEffect(() => {
    // Reset displayed or searched users when dependencies change
    if (props.users?.length > 0) {
      const initialChunk = props.users.slice(0, 10)
      setDisplayedUsers(initialChunk)
    }
  }, [props.users])

  // Update hasMore whenever displayedUsers changes
  useEffect(() => {
    const totalUsers = props.users?.length || 0
    setHasMore(displayedUsers.length < totalUsers)
  }, [displayedUsers, props.users])

  const loadMoreUsers = () => {
    if (props.loading || !props.users || props.users.length === 0) {
      return // Prevent load if there are no users or it's already loading
    }

    props.setLoading(true)

    if (searchText === '') {
      const nextChunk =
        props.users?.slice(displayedUsers.length, displayedUsers.length + 10) ||
        []

      // Update displayedUsers with the latest chunk, ensuring you are using the latest displayedUsers state
      setDisplayedUsers(prev => {
        const newDisplayedUsers = [...prev, ...nextChunk]
        // After updating, check if there are more users
        setHasMore(newDisplayedUsers.length < props.users.length)
        return newDisplayedUsers
      })
    } else {
      // Search functionality
      SearchAllUsers({ q: searchText, cursor, perPage: 10 })
        .then(res => {
          const newUsers = res.data.users
          setSearchedUsers(prev => [...prev, ...newUsers])
          setCursor(res.data.cursor)
          // After searching, set hasMore based on the number of results
          setHasMore(newUsers.length > 0)
        })
        .catch(error => {
          console.error('Error loading users:', error)
          setHasMore(false) // Stop loading if there's an error
        })
        .finally(() => props.setLoading(false))
    }
  }

  const handleSearchUser = (text: string = '') => {
    setSearchText(text)
    setSearchedUsers([]) // Clear previous search results
    setDisplayedUsers([]) // Clear displayed users
    setCursor('') // Reset cursor on new search
    setHasMore(true)

    if (text.trim() === '') {
      // Reset to default user list when search is cleared
      const initialChunk = props.users?.slice(0, 10) || []
      setDisplayedUsers(initialChunk)
    } else {
      props.setLoading(true)
      SearchAllUsers({ q: text, cursor: '', perPage: 10 })
        .then(res => {
          setSearchedUsers(res.data.users)
          setCursor(res.data.cursor)
          setHasMore(res.data.users.length > 0)
        })
        .catch(error => {
          console.error('Error searching users:', error)
        })
        .finally(() => props.setLoading(false))
    }
  }

  return (
    <div>
      <UserBox background={props.background}>
        {props.title && (
          <div className={'header'}>
            <p>{props.title}</p>
          </div>
        )}

        <div className={'body'}>
          <MyInput
            placeholder={'Search'}
            label={''}
            onChange={handleSearchUser}
            name={'search'}
            icon={'search'}
            background={'gray70'}
            border={'gray60'}
            color={'white100'}
          />
          {props.loading
            ? [1, 2, 3, 4, 5, 6].map(i => (
                <UserInfoBox
                  loading={props.loading}
                  key={i}
                  setLoading={loading => {
                    props.setLoading(loading)
                  }}
                />
              ))
            : null}
          <div
            className="users"
            style={{ maxHeight: '450px', overflow: 'auto' }}
          >
            <InfiniteScroll
              dataLength={
                searchText === '' ? displayedUsers.length : searchedUsers.length
              }
              next={loadMoreUsers}
              hasMore={hasMore}
              loader={
                !props?.users || props?.users?.length === 0 ? (
                  <div style={{ color: 'white', fontSize: '13px' }}>
                    Loading...
                  </div>
                ) : null
              }
              endMessage={
                props?.users &&
                props?.users?.length != 0 && (
                  <div style={{ color: 'white', fontSize: '13px' }}>
                    No users to load !
                  </div>
                )
              }
              scrollThreshold={0.9}
              scrollableTarget="scrollableDiv"
              height={'400px'}
            >
              {(searchText === '' ? displayedUsers : searchedUsers)
                .filter(item => item.id !== me.id)
                .map((user, index) => (
                  <UserInfoBox
                    user={user}
                    userId={me.id}
                    key={index}
                    setLoading={loading => {
                      props.setLoading(loading)
                    }}
                  />
                ))}
            </InfiniteScroll>
          </div>
        </div>
      </UserBox>
    </div>
  )
}
